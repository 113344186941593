<template>
    <div>
        <v-toolbar class="custom-toolbar" density="compact">
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon @click="openHelpDialog" color="brown-darken-2" size="large">mdi-help-box</v-icon>
            </v-btn>
        </v-toolbar>
        <div>
            <!-- Search textarea -->
            <v-row dense class="mt-3 mx-3">
                <v-col cols="12" md="8">
                    <v-textarea v-model="query" variant="outlined" label="טקסט לחיפוש" rows="2" no-resize
                        append-inner-icon="mdi-magnify" @click:append-inner="searchClicked"
                        placeholder="חפשו מקורות בתלמוד הבבלי. לדוגמא: צריך להתפלל עם כוונת הלב."
                        @keydown.enter.prevent="searchClicked" clearable hide-details ref="searchInput"></v-textarea>
                </v-col>
                <v-col class="hidden-sm-and-down" cols="2">
                    <v-btn variant="tonal" size="small" color="brown" @click="openQueriesDialog()">חיפושים
                        לדוגמא</v-btn>
                </v-col>
                <!-- Overlap percentage, hidden on mobile -->
                <v-col class="hidden-sm-and-down" v-if="sbertResults.length > 0 && bavliResults.length > 0" cols="2"
                    align="end">
                    <p>חפיפה: {{ calculateOverlapBavli }}%</p>
                </v-col>
            </v-row>
            <!-- Search settings: engine, num results, highlight keywords -->
            <v-row dense>
                <!-- Select engine -->
                <v-col cols="5" md="3">
                    <v-btn-toggle v-model="engine" mandatory variant="outlined" divided density="compact">
                        <v-btn :value="engines[0]">
                            <span v-if="isMobile">סמנטי</span>
                            <span v-else>חיפוש סמנטי</span>
                        </v-btn>
                        <v-btn :value="engines[1]">
                            <span v-if="isMobile">רגיל</span>
                            <span v-else>חיפוש רגיל</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
                <!-- Select number of results -->
                <v-col cols="7" md="3">
                    <v-btn-toggle v-model="numResults" mandatory variant="outlined" divided density="compact">
                        <v-btn v-for="value in [10, 20, 30]" :key="value" :value="value">{{ value }}</v-btn>
                    </v-btn-toggle>
                </v-col>
                <!-- Highlight keywords / Group by amud, hidden on mobile -->
                <v-col class="hidden-sm-and-down" cols="2">
                    <v-btn :color="highlight ? 'yellow' : 'white'" icon size="small" @click="toggleHighlight"
                        class="ml-1">
                        <v-icon>mdi-marker</v-icon>
                    </v-btn>
                    <v-btn :color="grouped ? 'brown' : 'white'" icon size="small" @click="grouped = !grouped">
                        <v-icon>mdi-book-open-variant</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <!-- Search results -->
            <v-row v-if="shortQuery" class="mx-10"><span class="text-red text-body-2">
                    לקבלת תוצאות משופרות נסו
                    להגדיל את מספר מילות החיפוש לארבע לפחות.</span></v-row>
            <v-row v-if="!grouped" class="mx-3">
                <v-col v-for="(result, index) in results" :key="index" cols="12" md="8">
                    <v-card variant="flat">
                        <!-- for sbert we emphasize results that were found by other engines -->
                        <v-card-title class="text-start">
                            <span v-if="engine === 'sbert'"
                                :class="{ 'font-weight-bold': otherEnginesAmuds.has(result.amud) }">{{ result.ref
                                }}</span>
                            <span v-else>{{ result.ref }}</span>
                        </v-card-title>
                        <v-card-text class="text-start text-body-1">
                            <div v-html="highlightResult(result.text)"></div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" :href="sefariaUrl(result.ref)" target="_blank">פתח בספריא</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else class="mx-3">
                <v-col cols="8">
                    <v-list>
                        <v-list-group v-for="(group, amud) in groupedResults" :key="amud" :value="amud">
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" prepend-icon="mdi-text-box-outline">
                                    <v-list-item-title class="text-start text-subtitle-1">{{ amud }} ({{ group.length
                                        }})</v-list-item-title>
                                </v-list-item>
                            </template>

                            <v-list-item v-for="(result, i) in group" :key="i" :value="result.title"
                                :title="result.title">
                                <v-card variant="flat">
                                    <v-card-title class="text-start text-subtitle-1">{{ result.ref }}</v-card-title>
                                    <v-card-text class="text-start text-body-1">
                                        <div v-html="highlightResult(result.text)"></div>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="primary" :href="sefariaUrl(result.ref)" target="_blank">פתח
                                            בספריא</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-col>
            </v-row>
        </div>
        <!-- Help dialog -->
        <v-dialog v-model="helpDialog" max-width="550px">
            <v-card>
                <v-card-title class="mt-2">
                    <span class="text-h5 text-brown">אודות מנוע החיפוש</span>
                </v-card-title>
                <v-card-text class="mr-2">
                    <ul>
                        <li v-for="(line, index) in helpContent" :key="index">{{ line }}</li>
                    </ul>
                </v-card-text>
                <v-card-actions style="direction: ltr;">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="helpDialog = false">סגירה</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Example Queries Dialog -->
        <v-dialog v-model="queriesDialog" max-width="550px">
            <v-card>
                <v-card-title class="mt-2">
                    <span class="text-h5 text-brown">חיפושים לדוגמא</span>
                </v-card-title>
                <v-card-text class="mr-2">
                    <ul>
                        <li v-for="(line, index) in exampleQueries" :key="index">
                            <a href="#" @click.prevent="selectExampleQuery(line)">{{ line }}</a>
                        </li>
                    </ul>
                </v-card-text>
                <v-card-actions style="direction: ltr;">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="queriesDialog = false">סגירה</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Ratings panel -->
        <div class="rating-panel hidden-sm-and-down" v-if="results.length > 0">
            <v-row dense>
                <v-col cols="12">
                    <span>דרגו את התוצאות:</span>
                </v-col>
                <v-col cols="12">
                    <v-rating v-model="rating" :item-labels="['חלש', '', 'בינוני', '', 'מצוין']" class="ma-2"
                        density="comfortable" item-label-position="top" color="brown-darken-3"
                        active-color="brown-darken-3" :readonly="feedbackSent"></v-rating> </v-col>
                <v-col cols="12">
                    <v-btn :disabled="feedbackSent" class="mb-1" size="large" color="brown" variant="text" @click="sendRating()">{{ feedbackSent ? 'תודה!' : 'שליחה' }}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { search, sendFeedback } from '@/utils/search.js';
import { groupBy } from 'lodash';

export default {
    data() {
        return {
            query: '',
            sbertResults: [],
            bavliResults: [],
            results: [],
            engine: 'sbert',  // default search engine
            numResults: 10,  // default number of results
            highlight: false,
            grouped: false,
            engines: ['sbert', 'bavli'],
            newSearch: false,
            helpDialog: false,
            queriesDialog: false,
            helpContent: [],
            exampleQueries: [],
            rating: 3,
            feedbackSent: false,
        };
    },
    computed: {
        shortQuery() {
            return this.query.trim().split(' ').length < 4 && this.engine === 'sbert' && this.newSearch;
        },
        sefariaUrl() {
            return (ref) => `https://www.sefaria.org.il/${ref.replace(/ /g, '_').replace(/:/g, '.')}?lang=he&with=Steinsaltz&lang2=he`;
        },
        groupedResults() {
            return groupBy(this.results, 'amud');
        },
        calculateOverlapBavli() {
            if (!this.sbertResults || !this.bavliResults) return 0;

            const sbertAmuds = new Set(this.sbertResults.map(result => result.amud));
            const bavliAmuds = new Set(this.bavliResults.map(result => result.amud));
            const overlap = [...sbertAmuds].filter(amud => bavliAmuds.has(amud));

            return ((overlap.length / sbertAmuds.size) * 100).toFixed(2);
        },
        otherEnginesAmuds() {
            const bavliAmuds = new Set(this.bavliResults.map(result => result.amud));
            return new Set([...bavliAmuds]);
        },
        isMobile() {
            return this.$vuetify.display.mobile;
        },
    },
    watch: {
        engine(newEngine) {
            if (newEngine === this.engines[0]) {
                this.results = this.sbertResults;
            } else if (newEngine === this.engines[1]) {
                this.results = this.bavliResults;
            }
        },
        query() {
            this.newSearch = false;
        },
    },
    methods: {
        // we get the results from all engines.
        // why? (1) instant upon tab switch (2) we can compare the results (that's the key thing here)
        async searchClicked() {
            this.newSearch = true;
            this.feedbackSent = false;
            // Blur the input field (remove focus) to close the keyboard on mobile
            this.$refs.searchInput.blur();
            const [sbertResults, bavliResults] = await Promise.all(
                this.engines.map(engine => search(this.query, engine, this.numResults))
            );

            this.sbertResults = this.processResults(sbertResults);
            this.bavliResults = this.processResults(bavliResults);

            // Set the results for the selected engine
            this.results = this[`${this.engine}Results`];
        },
        processResults(rawResults) {
            // Add "amud" to each result so the user will be able to group the results by amud
            return rawResults.map(result => {
                const amud = result.ref.split(':')[0];
                return { ...result, amud };
            });
        },
        toggleHighlight() {
            this.highlight = !this.highlight;
        },
        highlightResult(text) {
            if (!this.highlight) return text;
            var queryNoNiqqud = this.removeNiqqud(this.query);
            const queryWords = queryNoNiqqud.split(' ');
            const textWords = text.split(' ');
            const highlightedText = textWords.map(word => {
                if (queryWords.includes(word)) {
                    return `<mark>${word}</mark>`;
                } else {
                    return word;
                }
            });
            return highlightedText.join(' ');
        },
        removeNiqqud(text) {
            return text.normalize('NFD').replace(/[\u0591-\u05C7]/g, '');
        },
        async openHelpDialog() {
            const response = await fetch('/help.txt');
            const text = await response.text();
            this.helpContent = text.split('\n');
            this.helpDialog = true;
        },
        async openQueriesDialog() {
            const response = await fetch('/queries.txt');
            const text = await response.text();
            this.exampleQueries = text.split('\n');
            this.queriesDialog = true;
        },
        selectExampleQuery(query) {
            this.queriesDialog = false;
            this.query = query;
            this.searchClicked();
        },
        async sendRating() {
            try {
                // Send the feedback
                await sendFeedback(this.query, this.rating);

                // Update feedbackSent
                this.feedbackSent = true;
            } catch (error) {
                console.error('There was an error sending the rating:', error);
            }
        },
    },
};
</script>

<style scoped>
.custom-toolbar {
    background: url('~@/assets/bavli_header.png') repeat-x;
    background-blend-mode: multiply;
    background-color: #D7CCC8;
}

.rating-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 250px;
    /* create brown border on all sides, with radius */
    border: 0.7px solid #795548;
    border-radius: 10px 10px 0 0;
    margin-left: 10px;
    margin-bottom: 10px;
}
</style>