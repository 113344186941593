<template>
  <div class="home">
    <SearchBavli></SearchBavli>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBavli from '@/components/SearchBavli.vue'

export default {
  name: 'HomeView',
  components: {
    SearchBavli
  }
}
</script>
